import { Flex, Stack } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import {
  Contract,
  FrequencyContract,
  getReadjustmentIndexContract,
} from '../../../../services/ContractService';
import { useEngineeringTypeSelect } from '../../../../services/EngineeringTypeService';
import { useEquipmentSelect } from '../../../../services/EquipmentService';
import { useTechnologySelect } from '../../../../services/TechnologyService';
import { useTowerTypeSelect } from '../../../../services/TowerTypeService';
import ContainerStepComponent from '../../atoms/ContainerStepComponent/ContainerStepComponent';
import InputComponent from '../../atoms/InputComponent/InputComponent';
import InputSelectComponent from '../../atoms/InputSelectComponent/InputSelectComponent';
import { moneyMask } from '../../../../utils/functions/masks';
import TextComponent from '../../atoms/TextComponent/TextComponent';

type ContractStepperFormComponentProps = {
  form: Contract;
  setForm: Dispatch<SetStateAction<Contract>>;
  type?: 'contract' | 'bastChange';
};

export default function ContractStepperFormComponent(
  props: ContractStepperFormComponentProps
) {
  const listEquipment = useEquipmentSelect();
  const listTowerType = useTowerTypeSelect();
  const listTechnology = useTechnologySelect();
  const listEngineeringType = useEngineeringTypeSelect();
  const errorBaseReadjustmentDate =
    props.form.baseReadjustmentDate < props.form.startDate;
  const errorExpireDate = props.form.expireDate < props.form.startDate;
  function checkAdjustmentDate(
    startDateStr: string,
    adjustmentDateStr: string
  ): string | null {
    const startDate = new Date(startDateStr.replace(/-/g, '/'));
    const adjustmentDate = new Date(adjustmentDateStr.replace(/-/g, '/'));

    if (isNaN(startDate.getTime()) || isNaN(adjustmentDate.getTime())) {
      return 'Invalid date format.';
    } else if (
      startDateStr === '0001-01-01 00:00:00.0000000' ||
      adjustmentDateStr === '0001-01-01 00:00:00.0000000'
    ) {
      return null;
    }

    const oneYearInMilliseconds = 1000 * 60 * 60 * 24 * 365; // One year in milliseconds
    const differenceInMilliseconds =
      adjustmentDate.getTime() - startDate.getTime();

    if (differenceInMilliseconds < oneYearInMilliseconds) {
      return 'Reajuste é inferior a 12 meses.';
    } else {
      return null;
    }
  }

  return (
    <ContainerStepComponent title="Especificações do contrato">
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputComponent
          label="Data de ativação"
          type="date"
          value={props.form.activationDate}
          onChange={(input) =>
            props.setForm({ ...props.form, activationDate: input.target.value })
          }
        />
        <InputComponent
          label="Data de início"
          type="date"
          value={props.form.startDate}
          onChange={(input) =>
            props.setForm({ ...props.form, startDate: input.target.value })
          }
        />
        <InputComponent
          label="Data vencimento"
          type="date"
          value={props.form.expireDate}
          onChange={(input) =>
            props.setForm({ ...props.form, expireDate: input.target.value })
          }
          error={
            errorExpireDate
              ? 'Data de vencimento deve ser maior que a data de início'
              : ''
          }
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <Flex w={'100%'} flexDir={'column'}>
          <InputComponent
            label="Data base reajuste"
            type="date"
            value={props.form.baseReadjustmentDate}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                baseReadjustmentDate: input.target.value,
              })
            }
            error={
              errorBaseReadjustmentDate
                ? 'Data base reajuste deve ser maior que a data de início'
                : ''
            }
          />
          <TextComponent>
            {checkAdjustmentDate(
              props.form.startDate,
              props.form.baseReadjustmentDate
            )}
          </TextComponent>
        </Flex>

        <InputComponent
          label="Data instalação"
          type="date"
          value={props.form.installationDate}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              installationDate: input.target.value,
            })
          }
        />
        <InputComponent
          label="Data primeiro pagamento"
          type="date"
          value={props.form.firstPaymentDate}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              firstPaymentDate: input.target.value,
            })
          }
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputComponent
          label="ID Regional"
          placeholder="Digite o ID Regional"
          value={props.form.regionalId || ''}
          onChange={(input) =>
            props.setForm({ ...props.form, regionalId: input.target.value })
          }
        />
        <InputComponent
          label="ID Cluster"
          placeholder="Digite o ID Cluster"
          value={props.form.clusterId || ''}
          onChange={(input) =>
            props.setForm({ ...props.form, clusterId: input.target.value })
          }
        />
        <InputComponent
          label="M2"
          type="number"
          value={props.form.area?.toString()}
          onChange={(input) =>
            props.setForm({ ...props.form, area: Number(input.target.value) })
          }
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputComponent
          label="Matrícula IPTU"
          placeholder="Número da matrícula IPTU"
          value={props.form.iptuRegistration || ''}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              iptuRegistration: input.target.value,
            })
          }
        />
        <InputSelectComponent
          label="Pagamento IPTU"
          options={[
            {
              id: '',
              name: 'Selecione',
            },
            {
              id: 'true',
              name: 'Sim',
            },
            {
              id: 'false',
              name: 'Não',
            },
          ]}
          defaultValue={String(props.form.iptuPayment)}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              iptuPayment: input.target.value === 'true',
            })
          }
        />
        {props.type !== 'bastChange' && (
          <InputComponent
            label="Valor IPTU"
            placeholder="R$ 55,00"
            value={moneyMask(props.form.iptuValue?.toString())}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                iptuValue: input.target.value,
              })
            }
          />
        )}
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputSelectComponent
          label="Mês subsequente ou adiantado"
          options={[
            { id: '', name: 'Selecione' },
            {
              id: 'true',
              name: 'Adiantado',
            },
            {
              id: 'false',
              name: 'Mês subsequente',
            },
          ]}
          defaultValue={String(props.form.advancePayment)}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              advancePayment: input.target.value === 'true',
            })
          }
        />
        <InputSelectComponent
          label="Índice de Reajuste"
          placeholder="Selecione o índice"
          options={getReadjustmentIndexContract()}
          defaultValue={props.form.readjustmentIndexOption?.toString()}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              readjustmentIndexOption: Number(input.target.value),
            })
          }
        />

        <InputSelectComponent
          label="Periodicidade de pagamento"
          placeholder="Selecione a periodicidade"
          options={[
            {
              id: String(FrequencyContract.Anual),
              name: 'Anual',
            },
            {
              id: String(FrequencyContract.Bimestral),
              name: 'Bimestral',
            },
            {
              id: String(FrequencyContract.Mensal),
              name: 'Mensal',
            },
            {
              id: String(FrequencyContract.Semestral),
              name: 'Semestral',
            },
            {
              id: String(FrequencyContract.Negociacao),
              name: 'Em Negociação',
            },
          ]}
          defaultValue={String(props.form.frequency)}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              frequency: Number(input.target.value),
            })
          }
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputSelectComponent
          label="Tipo de torre"
          placeholder="Selecione a torre"
          options={listTowerType.data?.map((item) => ({
            id: item.id,
            name: item.description,
          }))}
          defaultValue={props.form.towerTypeId || ''}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              towerTypeId: input.target.value,
            })
          }
        />
        <InputSelectComponent
          label="Tipo de equipamento"
          placeholder="Selecione o equipamento"
          options={listEquipment.data?.map((item) => ({
            id: item.id,
            name: item.description,
          }))}
          defaultValue={props.form.equipmentId || ''}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              equipmentId: input.target.value,
            })
          }
        />
        <InputSelectComponent
          label="Tipo de tecnologia"
          placeholder="Selecione a tecnologia"
          options={listTechnology.data?.map((item) => ({
            id: item.id,
            name: item.description,
          }))}
          defaultValue={props.form.technologyId || ''}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              technologyId: input.target.value,
            })
          }
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputSelectComponent
          label="Tipo de engenharia"
          placeholder="Selecione o tipo de engenharia"
          options={listEngineeringType.data?.map((item) => ({
            id: item.id,
            name: item.description,
          }))}
          defaultValue={props.form.engineeringTypeId || ''}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              engineeringTypeId: input.target.value,
            })
          }
        />
        <InputSelectComponent
          label="Renovação automática"
          options={[
            {
              id: '',
              name: 'Selecione',
            },
            {
              id: 'true',
              name: 'Sim',
            },
            {
              id: 'false',
              name: 'Não',
            },
          ]}
          defaultValue={String(props.form.automaticRenovation)}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              automaticRenovation: input.target.value === 'true',
            })
          }
        />
        <InputComponent
          label="Número de renovações"
          placeholder="Digite o número de renovações"
          type="number"
          error={
            props.form.automaticRenovation && !props.form.numberOfRenewals
              ? 'Número de renovação é obrigatório'
              : ''
          }
          value={props.form.numberOfRenewals?.toString()}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              numberOfRenewals: input.target.value,
            })
          }
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        {props.type !== 'bastChange' && (
          <InputComponent
            label="Valor"
            placeholder="R$ 55,00"
            value={moneyMask(props.form.value?.toString())}
            onChange={(input) =>
              props.setForm({
                ...props.form,
                value: input.target.value,
              })
            }
          />
        )}
        <InputComponent
          label="Identificador Manual do Contrato"
          placeholder="Digite o identificador manual do Contrato"
          value={props.form.manualIdentifier || ''}
          onChange={(input) =>
            props.setForm({
              ...props.form,
              manualIdentifier: input.target.value,
            })
          }
        />

        <InputComponent
          label="Origem"
          placeholder="Digite a origem do contrato"
          value={props.form.origin || ''}
          onChange={(input) =>
            props.setForm({ ...props.form, origin: input.target.value })
          }
        />
      </Stack>
      <Stack mb={5} direction={'row'} spacing={'2%'}>
        <InputComponent
          label="Dados"
          placeholder="Digite Dados sobre o contrato aqui"
          type="textArea"
          value={props.form.data || ''}
          onChange={(input) =>
            props.setForm({ ...props.form, data: input.target.value })
          }
        />
        <InputComponent
          label="Observações"
          placeholder="Digite as observações aqui"
          type="textArea"
          value={props.form.comments || ''}
          onChange={(input) =>
            props.setForm({ ...props.form, comments: input.target.value })
          }
        />
      </Stack>
    </ContainerStepComponent>
  );
}
