import {
  PersonFunction,
  PhoneType,
  PhysicalPerson,
  SortDirection,
  postPhysicalPerson,
  putPhysicalPerson,
} from './PhysicalPersonService';
import { useQuery } from '@tanstack/react-query';
import Api, { getResp, getRespContent } from './Api';
import { openFile } from '../utils/functions/utility';
import {
  JuridicalPerson,
  postJuridicalPerson,
  putJuridicalPerson,
} from './JuridicalPersonService';

export type AddressesType = {
  owner: string;
  ownerId: string;
  street: string;
  district: string;
  complement: string;
  zipcode: string;
  type: number;
  cityId: string;
  stateId?: string;
  countryId?: string;
  coordenateId: string;
  number: string;
};

export type LocatorResp = {
  document: string;
  name: string;
  displayName: string;
  profilePicUrl: string;
  personId: string;
  date: string;
  addresses: {
    id: string;
    street: string;
    district: string;
    complement: string;
    zipcode: string;
    type: number;
    typeDescription: string;
    cityId: string;
    stateId: string;
    countryId: string;
    cityName: string;
    stateName: string;
    countryName: string;
    owner: string;
    ownerId: string;
  }[];
  emails: string[];
  email: string;
  phones: {
    number: string;
    type: PhoneType;
    typeDescription: string;
  }[];
  phone?: string;
  socialMedias: {
    url: string;
    type: number;
  }[];
  hasBankAccounts: boolean;
  contractLocator: number;
  contractsLocator: {
    id: string;
    value: number;
    serialNumber: string;
    signatureDate: string | null;
  }[];
  createdAt: string;
  type: LocatorPersonType;
  typeDescription: string;
  bankAccounts: {
    id: string;
    bankAgencyId: string;
    number: string;
    name: string;
    agency: string;
    account: string;
    corporateName: string;
    corporateDocument: string;
    primaryAccount: boolean;
    owner: string;
    ownerId: string;
  }[];
  id: string;
  locatorId: string;
  quota: string;
  percentage: number;
  receiver?: LocatorResp;
  attorney?: LocatorResp;
  totalValue: number;
};

export type LocatorPhysicalPerson = {
  id?: string;
  companyName?: string;
  companyPosition?: string;
  document: string;
  name: string;
  displayName: string;
  profilePicUrl: string;
  date: string;
  emails: string[];
  spouseId: string;
  spouce?: { id: string; name: string };
  nationality?: string;
  rg: string;
  phones: {
    number: string;
    type: number;
    typeDescription: string;
  }[];
  socialMedias: {
    url: string;
    type: number;
  }[];
  addresses: AddressesType[];
  function: PersonFunction;
  accounts: {
    agency: string;
    account: string;
    corporateName: string;
    corporateDocument: string;
    bankId: string;
    name?: string;
  }[];
  firstName: string;
  lastName: string;
  gender: number;
  detail: string;
  companyId: string;
  employee: {
    office: string;
    description: string;
    date: string;
    personId: string;
    teamId: string;
    higherId: string;
  } | null;
  user?: {
    id?: string;
    username?: string;
    email?: string;
    profileId?: string;
    permissions?: string[];
    customPermissions?: boolean;
    entities?: string[];
  };
  profiles?: {
    id: string;
    name: string;
  }[];
};

export type LocatorJuridicalPerson = {
  id?: string;
  companyName: string;
  companyPosition: string;
  document: string;
  name: string;
  displayName: string;
  profilePicUrl: string;
  date: string;
  emails: string[];
  phones: {
    number: string;
    type: number;
    typeDescription: string;
  }[];
  socialMedias: {
    url: string;
    type: number;
  }[];
  addresses: AddressesType[];
  accounts: {
    agency: string;
    account: string;
    corporateName: string;
    corporateDocument: string;
    bankId: string;
    name?: string;
  }[];
  bankAccounts?: {
    agency: string;
    account: string;
    corporateName: string;
    corporateDocument: string;
    bankAgencyId: string;
  }[];
  stateRegistration: string;
  establishmentFormat: string;
  physicalPerson: LocatorPhysicalPerson | null;
};

export type LocatorSendMessage = {
  personId: string;
  send_to?: string;
  subject: string;
  message: string;
  attachmentIds: string[];
};

export enum LocatorPersonType {
  Null = 0,
  Juridical = 1,
  Physical = 2,
}

export function useLocator(
  page: number,
  pageSize: number | null,
  search: string,
  searchField: string = 'name',
  sort: string = 'createdAt',
  direction: SortDirection = 'desc'
) {
  return useQuery(
    [`LocatorList`, page, sort, search, searchField, direction],
    async () => {
      const resp = await Api.get(`/Locator`, {
        params: {
          Offset: page,
          Limit: pageSize,
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
        },
      });
      return getRespContent<LocatorResp[]>(resp);
    }
  );
}

export function useAllLocator(
  search: string,
  searchField: string = 'name',
  sort: string = 'createdAt',
  direction: SortDirection = 'desc'
) {
  return useQuery(
    [`LocatorAll`, sort, search, searchField, direction],
    async () => {
      const resp = await Api.get(`/Locator`, {
        params: {
          [searchField]: search,
          SortColumn: sort,
          SortDirection: direction,
        },
      });
      return getResp<LocatorResp[]>(resp);
    }
  );
}

export function usePhysicalLocator(id: string, enabled: boolean) {
  return useQuery(
    [`PhysicalLocator`, id],
    async () => {
      const resp = await Api.get(`/physical-persons/${id}`);
      return getResp<LocatorPhysicalPerson>(resp);
    },
    { enabled: id.length >= 3 && enabled }
  );
}

export function useJuridicalLocator(id: string, enabled: boolean) {
  return useQuery(
    [`JuridicalLocator`, id],
    async () => {
      const resp = await Api.get(`/juridical-persons/${id}`);
      return getResp<LocatorJuridicalPerson>(resp);
    },
    { enabled: id.length >= 3 && enabled }
  );
}

export function useLocatorSelect() {
  return useQuery([`LocatorSelect`], async () => {
    const resp = await Api.get(`/Locator`);
    return getResp<LocatorResp[]>(resp);
  });
}

export function postPhysicalLocator(props: LocatorPhysicalPerson) {
  return Api.post('/Locator/physical-person', props);
}

export function postJuridicalLocator(props: LocatorJuridicalPerson) {
  return Api.post('/Locator/juridical-person', props);
}

export function putPhysicalLocator(props: LocatorPhysicalPerson) {
  return Api.put(`/physical-persons/${props.id}`, props);
}

export function putJuridicalLocator(props: LocatorJuridicalPerson) {
  return Api.put(`/juridical-persons/${props.id}`, props);
}

export function postLocator(
  juridicalPerson: LocatorJuridicalPerson,
  physicalPerson: LocatorPhysicalPerson,
  personType: LocatorPersonType
) {
  if (personType === LocatorPersonType.Physical) {
    return postPhysicalLocator(physicalPerson);
  } else {
    return postJuridicalLocator(juridicalPerson);
  }
}

export function putLocator(
  juridicalPerson: LocatorJuridicalPerson,
  physicalPerson: LocatorPhysicalPerson,
  personType: LocatorPersonType
) {
  if (personType === LocatorPersonType.Physical) {
    return putPhysicalLocator(physicalPerson);
  } else {
    return putJuridicalLocator(juridicalPerson);
  }
}

function savePhysicalLocator(props: PhysicalPerson) {
  if (props.id) {
    return putPhysicalPerson(props);
  }
  return postPhysicalPerson(props);
}

function saveJuridicalLocator(props: JuridicalPerson) {
  if (props.id) {
    return putJuridicalPerson(props);
  }
  return postJuridicalPerson(props);
}

export function saveLocator(
  juridicalPerson: JuridicalPerson,
  physicalPerson: PhysicalPerson,
  personType: LocatorPersonType
) {
  if (personType === LocatorPersonType.Physical) {
    return savePhysicalLocator(physicalPerson);
  } else {
    return saveJuridicalLocator(juridicalPerson);
  }
}

export function deleteLocator(id: string) {
  return Api.delete(`/Locator/${id}`);
}

export function getLocatorDetails(id: string) {
  return Api.get(`/Locator/${id}`).then((response) => {
    return getResp<LocatorResp>(response);
  });
}

export function getLocatorReport(
  search: string,
  searchField: string = 'name',
  sort: string = 'createdAt',
  direction: SortDirection = 'desc'
) {
  Api.get(`/Locator/report`, {
    params: {
      [searchField]: search,
      SortColumn: sort,
      SortDirection: direction,
    },
    responseType: 'blob',
  }).then((response) => {
    openFile(response);
  });
}

export function postLocatorSendMessage(props: LocatorSendMessage) {
  return Api.post('/Locator/send-message', props);
}
