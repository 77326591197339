import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import LoadingComponent from '../../atoms/LoadingComponent/LoadingComponent';
import { Flex } from '@chakra-ui/react';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';

type DashboardMapType = {
  isLoading?: boolean;
  latitude?: number;
  longitude?: number;
  width: number;
  height: number;
};

function isCoordinateValid(coordinate: number) {
  return (
    coordinate !== undefined &&
    coordinate !== null &&
    coordinate !== 0 &&
    !isNaN(coordinate) &&
    coordinate <= 90 &&
    coordinate >= -90
  );
}

export default function DashboardMap(props: DashboardMapType) {
  const latitude = props.latitude as number;
  const longitude = props.longitude as number;
  function isPositionValid() {
    return isCoordinateValid(latitude) && isCoordinateValid(longitude);
  }
  if (!isPositionValid()) {
    return (
      <div
        style={{
          height: props.height || '450px',
          width: props.width || '450px',
        }}
      >
        {props.isLoading ? (
          <Flex
            height={'100%'}
            w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <LoadingComponent />
          </Flex>
        ) : (
          <Flex
            w={'100%'}
            h={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <HeaderTextComponent textAlign={'center'} fontSize={14} subTitle>
              Coordenadas não encontradas <br />({latitude}, {longitude})
            </HeaderTextComponent>
          </Flex>
        )}
      </div>
    );
  }

  return (
    <MapContainer
      style={{
        height: props.height || '450px',
        width: props.width || '450px',
      }}
      center={[latitude, longitude]}
      zoom={17}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[latitude, longitude]}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  );
}
