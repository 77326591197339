import { useEffect, useState } from 'react';
import { useDebounce } from './debounce';
import { usePagination } from '../../components/UI/molecules/PaginationComponent/PaginationComponent';

type DebouncedQuerySearch = {
  searchInput: string;
  search: string;
  setSearch: (value: string) => void;
  searchField?: string;
  setSearchField: (value: string) => void;
  pagination: ReturnType<typeof usePagination>;
};

/**
 * Custom hook to manage debounced query search parameters from the URL.
 *
 * @param {string} initialSearchField - The initial value for the search field.
 * @returns {DebouncedQuerySearch} An object containing the search input, search value, setSearch function, search field, and setSearchField function.
 */
export function useDebouncedQuerySearch(
  initialSearchField: string
): DebouncedQuerySearch {
  const [searchField, setSearchField] = useQueryStringState(
    'searchField',
    initialSearchField
  );
  const pagination = usePagination(true);
  const debounce = useDebounce(() => {
    const query = new URLSearchParams(window.location.search);
    return query.get('search') || '';
  });

  const [searchInput, search, setSearch] = debounce;

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setSearch(query.get('search') || '');
  }, [setSearch]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    query.set('search', search);
    window.history.replaceState(null, '', `?${query.toString()}`);
  }, [searchField, search]);

  return {
    searchInput,
    search,
    setSearch,
    searchField,
    setSearchField,
    pagination,
  };
}

export function useQueryStringState(key: string, defaultValue?: string) {
  const [state, setState] = useState(() => {
    const query = new URLSearchParams(window.location.search);
    return query.get(key) || defaultValue;
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    query.set(key, state || '');
    window.history.replaceState(null, '', `?${query.toString()}`);
  }, [state, key]);

  return [state, setState] as const;
}

export function useBooleanQueryState(
  key: string,
  defaultValue: boolean | null = null
) {
  const [state, setState] = useQueryStringState(key, defaultValue?.toString());
  const setBooleanState = (value?: boolean | null) => {
    if (value === null) {
      setState(undefined);
    } else {
      setState(value?.toString());
    }
  };

  return [
    state === 'true' ? true : state === 'false' ? false : null,
    setBooleanState,
  ] as const;
}

export function useDateQueryState(key: string, defaultValue?: Date) {
  const [state, setState] = useQueryStringState(
    key,
    defaultValue?.toISOString()
  );

  return [
    state ? new Date(state) : defaultValue,
    (value?: Date) => setState(value?.toISOString()),
  ] as const;
}
