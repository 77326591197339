import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import {
  PaginationComponent,
  usePagination,
} from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDebounce } from '../../../utils/functions/debounce';
import { parseDateAndHour } from '../../../utils/functions/utility';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../../utils/functions/formatDate';
import { convertToMonetaryValue } from '../../../utils/functions/masks';
import {
  NegotiationHistoryResp,
  NegotiationHistoryStatus,
  useNegotiationHistory,
} from '../../../services/NegotiationHistory';
import { ReadjustmentIndex } from '../../../services/ReadjustmentIndexService';
import CreateNegotiationHistoryModal from '../../UI/organisms/CreateNegotiationHistoryModalComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import { FaEdit } from 'react-icons/fa';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';

export default function NegotiationHistoryTemplateComponent() {
  const params = useParams<{ id: string }>();
  const { pageSize, selectedPage, setSelectedPage } = usePagination();
  const [search] = useDebounce('');
  const [searchField] = React.useState('description');
  const [showModal, setShowModal] = React.useState(false);
  const [negotiationHistorySelectedId, setNegotiationHistoryId] =
    React.useState('');
  const [negotiationHistorySelectedData, setNegotiationHistorySelectedData] =
    React.useState<NegotiationHistoryResp>();
  const listNegotiationHistory = useNegotiationHistory(
    !params.id,
    params.id,
    selectedPage,
    pageSize,
    search,
    searchField
  );

  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  useEffect(() => {
    listNegotiationHistory.refetch();
  }, [listNegotiationHistory]);

  return (
    <>
      <Flex mb={2} justifyContent={'space-between'} alignItems={'center'}>
        <HeaderTextComponent goBack>
          Histórico de Negociações
        </HeaderTextComponent>
        <ButtonComponent
          margin={'0 10px 0 0'}
          disabled={!params.id}
          onSubmit={() => {
            setShowModal(true);
          }}
        >
          Nova Negociação
        </ButtonComponent>
      </Flex>

      <TableComponent
        ItemsHeader={[
          { item: 'Prazo em mesês' },
          { item: 'Valor total' },
          { item: 'Data' },
          { item: 'Indice' },
          { item: 'Criado em' },
          { item: 'Status' },
          { item: 'Ações' },
        ]}
        isLoading={!listNegotiationHistory.data}
        centered={true}
        emptyState={listNegotiationHistory.data?.metadata.dataSize === 0}
        data={
          listNegotiationHistory.data?.data.map((e, i) => ({
            items: [
              e.deadlineInMonths,
              convertToMonetaryValue(e.totalValue),
              `${formatDate(e.startingReference)} - ${formatDate(
                e.endingReference
              )}`,
              IndexToString(e.readjustmentIndex),
              parseDateAndHour(e.createdAt),
              getBadgeNegotiationHistory(e.status),
              <>
                {/* <IconButtonComponent
                  icon={<FaInfo />}
                  toolTipText="Detalhes"
                  ariaLabel="info"
                  onSubmit={() => {
                    setNegotiationHistoryId(e.id);
                    setNegotiationHistorySelectedData(
                      listNegotiationHistory.data.data[i]
                    );
                    setShowModal(true);
                  }}
                /> */}
                <IconButtonComponent
                  icon={<FaEdit />}
                  marginX={2}
                  toolTipText="Editar"
                  ariaLabel="edit"
                  colorScheme={'purple'}
                  onSubmit={() => {
                    setNegotiationHistoryId(e.id);
                    setNegotiationHistorySelectedData(
                      listNegotiationHistory.data.data[i]
                    );
                    setShowModal(true);
                  }}
                />
              </>,
            ],
          })) || []
        }
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listNegotiationHistory.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <CreateNegotiationHistoryModal
        negotiationHistorySelectedData={negotiationHistorySelectedData}
        negotiationHistorySelectedId={negotiationHistorySelectedId}
        contractId={params.id || ''}
        showModal={showModal}
        setShowModal={() => {
          setNegotiationHistoryId('');
          setShowModal(false);
        }}
        reloadData={() => listNegotiationHistory.refetch()}
      />
    </>
  );
}

export function IndexToString(
  readjustmentIndex: ReadjustmentIndex | undefined
): string {
  switch (readjustmentIndex) {
    case ReadjustmentIndex.IPCA:
      return 'IPCA';
    case ReadjustmentIndex.IPCAE:
      return 'IPCAE';
    case ReadjustmentIndex.IPCDI:
      return 'IPCDI';
    case ReadjustmentIndex.INPC:
      return 'INPC';
    case ReadjustmentIndex.IGPM:
      return 'IGPM';
    case ReadjustmentIndex.IGPDI:
      return 'IGPDI';
    case ReadjustmentIndex.SELIC:
      return 'SELIC';
    default:
      return '-';
  }
}
function getBadgeNegotiationHistory(status: NegotiationHistoryStatus) {
  switch (status) {
    case NegotiationHistoryStatus.Open:
      return <TagComponent size="md" colorScheme="green" text="Aberto" />;
    case NegotiationHistoryStatus.Finished:
      return <TagComponent size="md" colorScheme="red" text="Encerrado" />;
    case NegotiationHistoryStatus.Negotiation:
      return (
        <TagComponent size="md" colorScheme="yellow" text="Em andamento" />
      );
  }
}
