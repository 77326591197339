import { FaInfo } from 'react-icons/fa';
import TableComponent from '../../UI/molecules/TableComponent/TableComponent';
import { PaginationComponent } from '../../UI/molecules/PaginationComponent/PaginationComponent';
import { Flex, Stack } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import SearchSelectComponent from '../../UI/molecules/SearchSelectComponent/SearchSelectComponent';
import IconButtonComponent from '../../UI/atoms/ButtonComponent/IconButton';
import ButtonComponent from '../../UI/atoms/ButtonComponent/ButtonComponent';
import { TabsFilterComponent } from '../../UI/organisms/TabsFilterComponent/TabsFilterComponent';
import {
  ContractResp,
  getContractReport,
  StatusContract,
  useContract,
  useContractParamsValues,
} from '../../../services/ContractService';
import { getFirstIfAny } from '../../../utils/functions/utility';
import AvatarLabelComponent from '../../UI/atoms/AvatarLabelComponent/AvatarLabelComponent';
import {
  EntitiesSelect,
  useEntitiesSelectUser,
} from '../../../services/EntitiesService';
import { useUserData } from '../../../services/LoginService';
import { useNavigate } from 'react-router-dom';
import TagComponent from '../../UI/atoms/TagComponent/TagComponent';
import { formatDate } from '../../../utils/functions/formatDate';
import { convertToMonetaryValue } from '../../../utils/functions/masks';
import {
  TableColumns,
  SelectTableFieldsModalComponent,
  getHeaders,
} from '../../UI/organisms/SelectTableFieldsModalComponent/SelectTableFieldsModalComponent';
import { useLocalStorage } from '../../../services/LocalStorage';
import ContractConfigModalComponent from '../../UI/organisms/ContractConfigModalComponent/ContractConfigModalComponent';
import HeaderTextComponent from '../../UI/atoms/HeaderTextComponent/HeaderTextComponent';
import { notInfo } from '../../../pages/MyProfilePage';
import { Totalizer } from '../../UI/molecules/Totalizer/Totalizer';
import {
  useBooleanQueryState,
  useDateQueryState,
  useDebouncedQuerySearch,
  useQueryStringState,
} from '../../../utils/functions/useDebouncedQuerySearch';

export const defaultContractColumns: TableColumns = [
  {
    item: 'número',
    selected: true,
  },
  {
    item: 'locador',
    selected: true,
  },
  {
    item: 'locatário',
    selected: false,
  },
  {
    item: 'intermediário',
    selected: true,
  },
  {
    item: 'local',
    selected: false,
  },
  {
    item: 'criado por',
    selected: false,
  },
  {
    item: 'valor',
    selected: true,
  },
  {
    item: 'm2',
    selected: false,
  },
  {
    item: 'matrícula iptu',
    selected: false,
  },
  {
    item: 'pagamento iptu',
    selected: false,
  },
  {
    item: 'valor iptu',
    selected: false,
  },
  {
    item: 'assinado em',
    selected: true,
  },
  {
    item: 'vencimento',
    selected: true,
  },
  {
    item: 'data de assinatura',
    selected: false,
  },
  {
    item: 'data de início',
    selected: false,
  },
  {
    item: 'data de vencimento',
    selected: false,
  },
  {
    item: 'data de instalação',
    selected: false,
  },
  {
    item: 'primeiro pagamento',
    selected: false,
  },
  {
    item: 'índice de reajuste',
    selected: false,
  },
  {
    item: 'tipo de torre',
    selected: false,
  },
  {
    item: 'frequência',
    selected: false,
  },
  {
    item: 'tecnologia',
    selected: false,
  },
  {
    item: 'tipo de engenharia',
    selected: false,
  },
  {
    item: 'renovação automática',
    selected: false,
  },
  {
    item: 'origem',
    selected: false,
  },
  {
    item: 'id regional',
    selected: false,
  },
  {
    item: 'id cluster',
    selected: false,
  },
  {
    item: 'pagamento mês',
    selected: false,
  },
  {
    item: 'dados',
    selected: false,
  },
  {
    item: 'observações',
    selected: false,
  },
  {
    item: 'status',
    selected: true,
  },
];

export default function ContractTemplateComponent() {
  const user = useUserData();
  const navigate = useNavigate();
  const {
    search,
    searchField,
    searchInput,
    setSearch,
    setSearchField,
    pagination: { pageSize, selectedPage, setSelectedPage },
  } = useDebouncedQuerySearch('Locator');
  const [isActive, setIsActive] = useBooleanQueryState('isActive');
  const [contractStatus, setContractStatus] =
    useQueryStringState('contractStatus');
  const [startingSignatureDate, setStartingSignatureDate] = useDateQueryState(
    'startingSignatureDate'
  );
  const [endingSignatureDate, setEndingSignatureDate] = useDateQueryState(
    'endingSignatureDate'
  );
  const [startingDueDate, setStartingDueDate] =
    useDateQueryState('startingDueDate');
  const [endingDueDate, setEndingDueDate] = useDateQueryState('endingDueDate');
  const entities = useEntitiesSelectUser().data?.filter(
    (item) => item.id === user?.entity?.id
  );

  const listContract = useContract(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc',
    isActive,
    null,
    startingSignatureDate,
    endingSignatureDate,
    startingDueDate,
    endingDueDate,
    contractStatus
  );
  const contractValues = useContractParamsValues(
    selectedPage,
    pageSize,
    search,
    searchField,
    'createdAt',
    'desc',
    isActive,
    null,
    startingSignatureDate,
    endingSignatureDate,
    startingDueDate,
    endingDueDate,
    contractStatus
  );
  const [showModalSelect, setShowModalSelect] = React.useState(false);
  const [showModalConfig, setShowModalConfig] = React.useState(false);
  const [columns, setColumns] = useLocalStorage(
    `${user?.userId}-contract`,
    defaultContractColumns
  );
  if (columns && defaultContractColumns.length !== columns?.length) {
    setColumns(defaultContractColumns);
  }
  const buttons = useCallback(
    (id: string) => (
      <>
        <IconButtonComponent
          icon={<FaInfo />}
          toolTipText="Detalhes"
          ariaLabel="info"
          marginX={'8px'}
          onSubmit={() => {
            navigate(`/contract-details/${id}`);
          }}
        />
      </>
    ),
    [navigate]
  );
  function onSelectedPageChanged(page: number) {
    setSelectedPage(page);
  }
  const tabName = [
    {
      title: 'Todos',
      onClick: () => {
        setIsActive(null);
      },
    },
    {
      title: 'Ativos',
      onClick: () => {
        setIsActive(true);
      },
    },
    {
      title: 'Inativos',
      onClick: () => {
        setIsActive(false);
      },
    },
  ];

  function clearFields() {
    setStartingSignatureDate(undefined);
    setEndingSignatureDate(undefined);
    setStartingDueDate(undefined);
    setEndingDueDate(undefined);
    setContractStatus('');
    setSearch('');
  }

  return (
    <>
      <Stack paddingBottom={5} justifyContent={'space-between'}>
        <HeaderTextComponent goBack>Contratos</HeaderTextComponent>
      </Stack>
      <Stack
        direction={'row'}
        paddingBottom={5}
        justifyContent={'space-between'}
      >
        <TabsFilterComponent tabList={tabName} />
        <Flex>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => setShowModalConfig(true)}
          >
            Configurações
          </ButtonComponent>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => {
              getContractReport(
                search,
                searchField,
                'locator',
                'asc',
                isActive
              );
            }}
          >
            Exportar
          </ButtonComponent>
          <ButtonComponent
            margin={'0 10px 0 0'}
            onSubmit={() => navigate('/new-contract')}
          >
            Novo Cadastro
          </ButtonComponent>
          <SearchSelectComponent
            onChangeText={(input) => setSearch(input.target.value)}
            inputValue={searchInput}
            onClean={() => {
              clearFields();
            }}
            selectDefaultValue={searchField}
            onChange={(item) => {
              clearFields();
              setSearchField(item.target.value);
            }}
            options={[
              { id: 'locator', name: 'Locador' },
              { id: 'serialNumber', name: 'Número' },
              {
                id: 'ContractStatus',
                name: 'Status',
                type: 'select',
                options: [
                  { id: '', name: 'Todos' },
                  {
                    id: StatusContract.EmCadastramento.toString(),
                    name: 'Em Cadastramento',
                  },

                  {
                    id: StatusContract.PendenteDeAssinatura.toString(),
                    name: 'Pendente de assinatura',
                  },
                  {
                    id: StatusContract.Assinado.toString(),
                    name: 'Assinado',
                  },

                  {
                    id: StatusContract.Cancelado.toString(),
                    name: 'Cancelado',
                  },
                  {
                    id: StatusContract.EmElaboraçãoDeMinuta.toString(),
                    name: 'Em elaboração de minuta',
                  },
                  {
                    id: StatusContract.PendingChanges.toString(),
                    name: 'Alterações Pendentes',
                  },
                  {
                    id: StatusContract.PendenteDeAtivação.toString(),
                    name: 'Pendente de ativação',
                  },
                ],
                onChageSelect: (e) => setContractStatus(e.target.value),
              },
              {
                id: 'SignatureDate',
                name: 'Assinado em (período)',
                type: 'date-range',
                dateRange: {
                  startDate: startingSignatureDate,
                  endDate: endingSignatureDate,
                },
                onChangeDateRange: (range) => {
                  clearFields();
                  setStartingSignatureDate(range.startDate);
                  setEndingSignatureDate(range.endDate);
                },
              },
              {
                id: 'DueDate ',
                name: 'Vencimento em (período)',
                type: 'date-range',
                dateRange: {
                  startDate: startingDueDate,
                  endDate: endingDueDate,
                },
                onChangeDateRange: (range) => {
                  clearFields();
                  setStartingDueDate(range.startDate);
                  setEndingDueDate(range.endDate);
                },
              },
            ]}
          />
        </Flex>
      </Stack>
      <TableComponent
        ItemsHeader={getHeaders(columns, () => setShowModalSelect(true))}
        isLoading={listContract.isLoading}
        centered={true}
        emptyState={listContract.data?.metadata.dataSize === 0}
        data={getContractFields(
          columns,
          listContract.data?.data,
          entities ?? [],
          buttons
        )}
      />
      <Totalizer
        total={contractValues.data?.totalValue ?? 0}
        currentPage={contractValues.data?.currentValue ?? 0}
      />
      <PaginationComponent
        onSelectedPageChanged={onSelectedPageChanged}
        selectedPage={selectedPage}
        arrayLength={listContract.data?.metadata.dataSize || 0}
        maxPageItens={pageSize}
      ></PaginationComponent>
      <SelectTableFieldsModalComponent
        showModal={showModalSelect}
        setShowModal={() => setShowModalSelect(false)}
        columns={columns}
        setColumns={setColumns}
      />
      <ContractConfigModalComponent
        showModal={showModalConfig}
        setShowModal={() => setShowModalConfig(false)}
      />
    </>
  );
}

export function StatusColorByTypeContract(props: { type: StatusContract }) {
  switch (props.type) {
    case StatusContract.EmCadastramento:
      return (
        <TagComponent
          size={'md'}
          colorScheme={'blue'}
          text="Em cadastramento"
        />
      );
    case StatusContract.PendenteDeAssinatura:
      return (
        <TagComponent
          size={'md'}
          colorScheme={'teal'}
          text="Pendente de assinatura"
        />
      );
    case StatusContract.Assinado:
      return <TagComponent size={'md'} colorScheme={'green'} text="Assinado" />;
    case StatusContract.Inativo:
      return <TagComponent size={'md'} colorScheme={'gray'} text="Inativo" />;
    case StatusContract.Cancelado:
      return <TagComponent size={'md'} colorScheme={'red'} text="Cancelado" />;

    default:
      return <TagComponent size={'md'} colorScheme={'green'} text="Assinado" />;
  }
}

export function ContractStatusTag(props: {
  type: StatusContract;
  typeDescription: string;
}) {
  if (props.type === StatusContract.EmCadastramento) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'blue'}
        text={props.typeDescription}
      />
    );
  }
  if (props.type === StatusContract.PendenteDeAssinatura) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'yellow'}
        text={props.typeDescription}
      />
    );
  }
  if (props.type === StatusContract.PendenteDeAtivação) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'orange'}
        text={props.typeDescription}
      />
    );
  }
  if (props.type === StatusContract.EmElaboraçãoDeMinuta) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'purple'}
        text={props.typeDescription}
      />
    );
  }
  if (props.type === StatusContract.Assinado) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'green'}
        text={props.typeDescription}
      />
    );
  }
  if (props.type === StatusContract.Inativo) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'gray'}
        text={props.typeDescription}
      />
    );
  }
  if (props.type === StatusContract.Cancelado) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'red'}
        text={props.typeDescription}
      />
    );
  }
  if (props.type === StatusContract.PendingChanges) {
    return (
      <TagComponent
        size={'md'}
        colorScheme={'purple'}
        text={props.typeDescription}
      />
    );
  }
  return (
    <>
      <TagComponent
        size={'md'}
        colorScheme={'gray'}
        text={props.typeDescription}
      />
    </>
  );
}

export function getContractFields(
  columns: TableColumns | undefined,
  contracts: ContractResp[] | undefined,
  entities: EntitiesSelect[],
  buttons: (id: string) => React.ReactNode
) {
  return (
    contracts?.map((e, i) => {
      const items = [];
      if (columns) {
        if (columns.some((item) => item.item === 'número' && item.selected))
          items.push(e.serialNumber);
        if (columns.some((item) => item.item === 'locador' && item.selected))
          items.push(
            <AvatarLabelComponent
              key={i}
              label={getFirstIfAny(e.locators)?.name}
              src={getFirstIfAny(e.locators)?.profilePicUrl}
            />
          );
        if (columns.some((item) => item.item === 'locatário' && item.selected))
          items.push(
            <AvatarLabelComponent
              key={i}
              label={getFirstIfAny(entities)?.name}
              src={getFirstIfAny(entities)?.entityImage}
            />
          );
        if (
          columns.some((item) => item.item === 'intermediário' && item.selected)
        )
          items.push(
            e.intermediary ? (
              <AvatarLabelComponent
                key={i}
                label={e.intermediary?.name}
                subLabel={'Intermediário'}
                src={e.intermediary?.profilePicUrl}
              />
            ) : (
              'Não possui intermediário'
            )
          );
        if (columns.some((item) => item.item === 'local' && item.selected))
          items.push(
            `${getFirstIfAny(e.locations)?.street} ${
              getFirstIfAny(e.locations)?.complement
            }`
          );
        if (columns.some((item) => item.item === 'criado por' && item.selected))
          items.push(e.createdByName);
        if (columns.some((item) => item.item === 'valor' && item.selected))
          items.push(convertToMonetaryValue(e.value));
        if (columns.some((item) => item.item === 'm2' && item.selected))
          items.push(e.area.toString());
        if (
          columns.some(
            (item) => item.item === 'matrícula iptu' && item.selected
          )
        )
          items.push(e.iptuRegistration);
        if (
          columns.some(
            (item) => item.item === 'pagamento iptu' && item.selected
          )
        )
          items.push(e.iptuPayment ? 'Sim' : 'Não');
        if (columns.some((item) => item.item === 'valor iptu' && item.selected))
          items.push(convertToMonetaryValue(e.iptuValue));
        if (
          columns.some((item) => item.item === 'assinado em' && item.selected)
        )
          items.push(notInfo(formatDate(e.signatureDate)));
        if (columns.some((item) => item.item === 'vencimento' && item.selected))
          items.push(formatDate(e.expireDate));
        if (
          columns.some(
            (item) => item.item === 'data assinatura' && item.selected
          )
        )
          items.push(formatDate(e.signatureDate));
        if (
          columns.some((item) => item.item === 'data início' && item.selected)
        )
          items.push(formatDate(e.startDate));
        if (
          columns.some(
            (item) => item.item === 'data vencimento' && item.selected
          )
        )
          items.push(formatDate(e.dueDate));
        if (
          columns.some(
            (item) => item.item === 'data instalação' && item.selected
          )
        )
          items.push(formatDate(e.installationDate));
        if (
          columns.some(
            (item) => item.item === 'primeiro pagamento' && item.selected
          )
        )
          items.push(formatDate(e.firstPaymentDate));
        if (
          columns.some(
            (item) => item.item === 'índice de reajuste' && item.selected
          )
        )
          items.push(e.indexDescription);
        if (
          columns.some((item) => item.item === 'tipo de torre' && item.selected)
        )
          items.push(e.towerType?.typeDescription);
        if (columns.some((item) => item.item === 'frequência' && item.selected))
          items.push(e.frequencyDescription);
        if (columns.some((item) => item.item === 'tecnologia' && item.selected))
          items.push(e.technology?.typeDescription);
        if (
          columns.some(
            (item) => item.item === 'tipo de engenharia' && item.selected
          )
        )
          items.push(e.engineeringType?.description);
        if (
          columns.some(
            (item) => item.item === 'renovação automática' && item.selected
          )
        )
          items.push(e.automaticRenovation ? 'Sim' : 'Não');
        if (columns.some((item) => item.item === 'origem' && item.selected))
          items.push(e.origin);
        if (
          columns.some((item) => item.item === 'id regional' && item.selected)
        )
          items.push(e.regionalId);
        if (columns.some((item) => item.item === 'id cluster' && item.selected))
          items.push(e.clusterId);
        if (
          columns.some((item) => item.item === 'pagamento mês' && item.selected)
        )
          items.push(e.advancePayment ? 'Adiantado' : 'Mês subsequente');
        if (columns.some((item) => item.item === 'dados' && item.selected))
          items.push(e.data);
        if (
          columns.some((item) => item.item === 'observações' && item.selected)
        )
          items.push(e.comments);
        if (columns.some((item) => item.item === 'status' && item.selected))
          items.push(
            <ContractStatusTag
              type={e.contractStatus}
              typeDescription={e.contractStatusDescription}
            />
          );
      }
      return {
        items: [...items, buttons(e.id)],
      };
    }) ?? []
  );
}
