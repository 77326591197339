import { useQuery } from '@tanstack/react-query';
import Api, { getResp } from './Api';

export type ListResp = {
  id: string;
  name: string;
};

export function useCountryList() {
  return useQuery([`CountryList`], async () => {
    const resp = await Api.get(`/Country`);
    return getResp<ListResp[]>(resp);
  });
}

export default function useStateList(country: string) {
  return useQuery(
    [`StateList`, country],
    async () => {
      const resp = await Api.get(`/State/select`, {
        params: {
          countryId: country,
        },
      });
      return getResp<ListResp[]>(resp);
    },
    { enabled: country.length >= 3 }
  );
}

export function useStateListNoFilter() {
  return useQuery([`StateList`], async () => {
    const resp = await Api.get(`/State/select`);
    return getResp<ListResp[]>(resp);
  });
}

export function useCityList(state: string) {
  return useQuery(
    [`CityList`, state],
    async () => {
      const resp = await Api.get(`/City/select`, {
        params: {
          stateId: state,
        },
      });
      return getResp<ListResp[]>(resp);
    },
    { enabled: state.length >= 3 }
  );
}

type CepAddressResp = {
  street: string;
  district: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  cityId: string;
  cityName: string;
  stateId: string;
  countryId: string;
};

export function useAddressCep(cep: string) {
  return useQuery(
    [`CepAddress`, cep],
    async () => {
      const resp = await Api.get(`/Cep/${cep}`);
      return getResp<CepAddressResp>(resp);
    },
    { enabled: cep.length >= 8 }
  );
}
