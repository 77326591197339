import { Flex, useToast } from '@chakra-ui/react';
import ButtonComponent from '../../atoms/ButtonComponent/ButtonComponent';
import ModalStructureComponent from '../../molecules/ModalStructureComponent/ModalStructureComponent';
import {
  ContractChangeRequestResp,
  putContractChangeRequest,
} from '../../../../services/ContractChangeRequestService';
import { formatDate } from '../../../../utils/functions/formatDate';
import { convertToMonetaryValue } from '../../../../utils/functions/masks';
import HeaderTextComponent from '../../atoms/HeaderTextComponent/HeaderTextComponent';
import { LabelInfoDetails } from '../../../../pages/MyProfilePage';
import {
  FrequencyContract,
  useContractDetails,
} from '../../../../services/ContractService';
import { ReadjustmentIndex } from '../../../../services/ReadjustmentIndexService';
import { useTechnologySelect } from '../../../../services/TechnologyService';
import { useEngineeringTypeSelect } from '../../../../services/EngineeringTypeService';
import { useEquipmentSelect } from '../../../../services/EquipmentService';
import { useTowerTypeSelect } from '../../../../services/TowerTypeService';
import { useState } from 'react';
import { AcceptMessageModalComponent } from '../../organisms/AcceptMessageModalComponent/AcceptMessageModalComponent';
import { showToast } from '../../../../utils/showToast';
import { StatusColorByTypeContract } from '../../../templates/ContractTemplateComponent/ContractTemplateComponent';
import TextComponent from '../../atoms/TextComponent/TextComponent';

enum RequestType {
  Additive = 0,
  Renovation = 1,
  Readjustment = 2,
  Termination = 3,
  Null = 4,
}
export function ContractChangeRequestValueModalComponent(props: {
  showModal: boolean;
  selectedContractChangeRequest?: ContractChangeRequestResp;
  setShowModal: () => void;
  refetch: () => void;
}) {
  const listTechnology = useTechnologySelect().data;
  const listEngineeringType = useEngineeringTypeSelect().data;
  const listEquipmentType = useEquipmentSelect().data;
  const listTowerType = useTowerTypeSelect().data;
  const toast = useToast();
  const [approve, setApprove] = useState(false);
  const [showModalApprove, setShowModalApprove] = useState(false);
  // const listPerson = usePerson(
  //   1,
  //   10000,
  //   '',
  //   'searchField',
  //   'sort',
  //   undefined,
  //   null
  // ).data?.data;

  const contract = useContractDetails(
    props.selectedContractChangeRequest?.contractId || ''
  ).data;

  function ReturnChangeRequestData(type: { type: RequestType | undefined }) {
    if (type.type === RequestType.Renovation) {
      return (
        <>
          <Flex flexDir={'column'} mb={4}>
            <HeaderTextComponent mb={2} fontSize={22} subTitle>
              Valores atuais
            </HeaderTextComponent>
            <Flex>
              <LabelInfoDetails
                label={'Data de vencimento'}
                details={formatDate(contract?.expireDate)}
              />
            </Flex>
          </Flex>
          <Flex flexDir={'column'}>
            <HeaderTextComponent mb={2} fontSize={22} subTitle>
              Valores alterados
            </HeaderTextComponent>

            <Flex>
              <LabelInfoDetails
                label={'Data de vencimento'}
                details={formatDate(
                  props.selectedContractChangeRequest?.actionContent
                    ?.NewExpirationDate
                )}
              />
            </Flex>
          </Flex>
        </>
      );
    }
    if (type.type === RequestType.Readjustment) {
      return (
        <>
          <Flex flexDir={'column'} mb={4}>
            <HeaderTextComponent mb={2} fontSize={22} subTitle>
              Valores atuais
            </HeaderTextComponent>
            <Flex>
              <LabelInfoDetails
                label={'Value'}
                details={convertToMonetaryValue(Number(contract?.value))}
              />
              <LabelInfoDetails
                label={'Data base de reajuste'}
                details={formatDate(contract?.baseReadjustmentDate)}
              />
              <LabelInfoDetails
                label={'Índice de Reajuste'}
                details={contract?.indexDescription}
              />
            </Flex>
          </Flex>
          <Flex flexDir={'column'}>
            <HeaderTextComponent mb={2} fontSize={22} subTitle>
              Valores alterados
            </HeaderTextComponent>

            <Flex>
              <LabelInfoDetails
                label={'Value'}
                details={convertToMonetaryValue(
                  props.selectedContractChangeRequest?.actionContent?.NewValue
                )}
              />

              <LabelInfoDetails
                label={'Data base de reajuste'}
                details={formatDate(
                  props.selectedContractChangeRequest?.actionContent?.Competence
                )}
              />
              <LabelInfoDetails
                label={'Índice de Reajuste'}
                details={getIdexNameByIndex(
                  props.selectedContractChangeRequest?.actionContent
                    ?.ReadjustmentIndex
                )}
              />
            </Flex>
          </Flex>
        </>
      );
    }
    if (type.type === RequestType.Termination) {
      return (
        <>
          <Flex flexDir={'column'} mb={4}>
            <HeaderTextComponent mb={2} fontSize={22} subTitle>
              Detalhes do distrato
            </HeaderTextComponent>
            <Flex>
              <LabelInfoDetails
                label={'Data de encerramento'}
                details={formatDate(
                  props.selectedContractChangeRequest?.actionContent
                    .TerminationDate
                )}
              />
              <LabelInfoDetails
                label={'Motivo do encerramento'}
                details={
                  props.selectedContractChangeRequest?.actionContent.Motivation
                }
              />
            </Flex>
          </Flex>
        </>
      );
    }
    if (type.type === RequestType.Additive) {
      return (
        <>
          <Flex flexDir={'column'}>
            <HeaderTextComponent mb={2} fontSize={22} subTitle>
              Valores atuais
            </HeaderTextComponent>
            <Flex flexWrap={'wrap'}>
              <LabelInfoDetails
                label={'Data de ativação'}
                details={formatDate(contract?.activationDate)}
              />
              <LabelInfoDetails
                label={'Data Base de reajuste'}
                details={formatDate(contract?.baseReadjustmentDate)}
              />
              <LabelInfoDetails
                label={'Data de vencimento'}
                details={formatDate(contract?.expireDate)}
              />
              <LabelInfoDetails
                label={'Data de Primeiro pagamento'}
                details={formatDate(contract?.firstPaymentDate)}
              />
              <LabelInfoDetails
                label={'Data de Instalação'}
                details={formatDate(contract?.installationDate)}
              />

              <LabelInfoDetails
                label={'  Data de início'}
                details={formatDate(contract?.startDate)}
              />

              <LabelInfoDetails
                label={'Renovação automática'}
                details={contract?.automaticRenovation ? 'Sim' : 'Não'}
              />
              <LabelInfoDetails
                label={'Número de renovações'}
                details={contract?.numberOfRenewals?.toString()}
              />
              <LabelInfoDetails
                label={'Pagamento IPTU'}
                details={contract?.iptuPayment ? 'Sim' : 'Não'}
              />
              <LabelInfoDetails
                label={'Mês subsequente ou adiantado'}
                details={
                  contract?.advancePayment ? 'Adiantado' : 'Mês subsequente'
                }
              />
              <LabelInfoDetails
                label={'Periodicidade'}
                details={getFrequencyNameByType(contract?.frequency)}
              />

              <LabelInfoDetails
                label={'Índice de Reajuste'}
                details={getIdexNameByIndex(contract?.index)}
              />
              <LabelInfoDetails
                label={'Valor'}
                details={convertToMonetaryValue(Number(contract?.value))}
              />
              <LabelInfoDetails
                label={'Valor IPTU'}
                details={convertToMonetaryValue(Number(contract?.iptuValue))}
              />
              <LabelInfoDetails
                label={'Area (M²)'}
                details={contract?.area.toString()}
              />
              <LabelInfoDetails
                label={'ID Cluster'}
                details={contract?.clusterId}
              />
              <LabelInfoDetails
                label={'ID Regional'}
                details={contract?.regionalId}
              />

              <LabelInfoDetails
                label={'Matrícula IPTU'}
                details={contract?.iptuRegistration}
              />
              <LabelInfoDetails label={'Dados'} details={contract?.data} />
              <LabelInfoDetails
                label={'Observações'}
                details={contract?.comments}
              />
              <LabelInfoDetails label={'Origem'} details={contract?.origin} />

              <LabelInfoDetails
                label={'Identificador Manual do Contrato'}
                details={contract?.manualIdentifier}
              />
              <LabelInfoDetails
                label={'Tipo de torre'}
                details={getDescriptionByItemId(
                  listTowerType,
                  contract?.towerTypeId || ''
                )}
              />

              <LabelInfoDetails
                label={'Tipo de tecnologia'}
                details={getDescriptionByItemId(
                  listTechnology,
                  contract?.technologyId || ''
                )}
              />
              <LabelInfoDetails
                label={'Tipo de  engenharia'}
                details={getDescriptionByItemId(
                  listEngineeringType,
                  contract?.engineeringTypeId || ''
                )}
              />
              <LabelInfoDetails
                label={'Tipo de  equipamento'}
                details={getDescriptionByItemId(
                  listEquipmentType,
                  contract?.equipmentId || ''
                )}
              />
            </Flex>
          </Flex>
          <Flex flexDir={'column'}>
            <HeaderTextComponent mb={2} fontSize={22} subTitle>
              Valores alterados
            </HeaderTextComponent>
            <Flex flexWrap={'wrap'}>
              {props.selectedContractChangeRequest?.actionContent
                .ActivationDate !== contract?.activationDate && (
                <LabelInfoDetails
                  label={'Data de ativação'}
                  details={formatDate(
                    props.selectedContractChangeRequest?.actionContent
                      .ActivationDate
                  )}
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .BaseReadjustmentDate !== contract?.baseReadjustmentDate && (
                <LabelInfoDetails
                  label={'Data Base de reajuste'}
                  details={formatDate(
                    props.selectedContractChangeRequest?.actionContent
                      .BaseReadjustmentDate
                  )}
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.ExpireDate !==
                contract?.expireDate && (
                <LabelInfoDetails
                  label={'Data de vencimento'}
                  details={formatDate(
                    props.selectedContractChangeRequest?.actionContent
                      .ExpireDate
                  )}
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .FirstPaymentDate !== contract?.firstPaymentDate && (
                <LabelInfoDetails
                  label={'Data de Primeiro pagamento'}
                  details={formatDate(
                    props.selectedContractChangeRequest?.actionContent
                      .FirstPaymentDate
                  )}
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .InstallationDate !== contract?.installationDate && (
                <LabelInfoDetails
                  label={'Data de Instalação'}
                  details={formatDate(
                    props.selectedContractChangeRequest?.actionContent
                      .InstallationDate
                  )}
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.StartDate !==
                contract?.startDate && (
                <LabelInfoDetails
                  label={'  Data de início'}
                  details={formatDate(
                    props.selectedContractChangeRequest?.actionContent.StartDate
                  )}
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .AutomaticRenovation !== contract?.automaticRenovation && (
                <LabelInfoDetails
                  label={'Renovação automática'}
                  details={
                    props.selectedContractChangeRequest?.actionContent
                      .AutomaticRenovation
                      ? 'Sim'
                      : 'Não'
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .NumberOfRenewals !== contract?.numberOfRenewals && (
                <LabelInfoDetails
                  label={'Número de renovações'}
                  details={
                    props.selectedContractChangeRequest?.actionContent
                      .NumberOfRenewals
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .IPTUPayment !== contract?.iptuPayment && (
                <LabelInfoDetails
                  label={'Pagamento IPTU'}
                  details={
                    props.selectedContractChangeRequest?.actionContent
                      .IPTUPayment
                      ? 'Sim'
                      : 'Não'
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .AdvancePayment !== contract?.advancePayment && (
                <LabelInfoDetails
                  label={'Mês subsequente ou adiantado'}
                  details={
                    props.selectedContractChangeRequest?.actionContent
                      .AdvancePayment
                      ? 'Adiantado'
                      : 'Mês subsequente'
                  }
                />
              )}
              {getFrequencyNameByType(
                props.selectedContractChangeRequest?.actionContent.Frequency
              ) !== getFrequencyNameByType(contract?.frequency) && (
                <LabelInfoDetails
                  label={'Periodicidade'}
                  details={getFrequencyNameByType(
                    props.selectedContractChangeRequest?.actionContent.Frequency
                  )}
                />
              )}
              {getIdexNameByIndex(
                props.selectedContractChangeRequest?.actionContent
                  .ReadjustmentIndexOption
              ) !== getIdexNameByIndex(contract?.index) && (
                <LabelInfoDetails
                  label={'Índice de Reajuste'}
                  details={getIdexNameByIndex(
                    props.selectedContractChangeRequest?.actionContent
                      .ReadjustmentIndexOption
                  )}
                />
              )}
              {convertToMonetaryValue(
                props.selectedContractChangeRequest?.actionContent.Value
              ) !== convertToMonetaryValue(contract?.value || 0) && (
                <LabelInfoDetails
                  label={'Valor'}
                  details={convertToMonetaryValue(
                    props.selectedContractChangeRequest?.actionContent.Value
                  )}
                />
              )}
              {convertToMonetaryValue(
                props.selectedContractChangeRequest?.actionContent.IPTUValue
              ) !== convertToMonetaryValue(contract?.iptuValue || 0) && (
                <LabelInfoDetails
                  label={'Valor IPTU'}
                  details={convertToMonetaryValue(
                    props.selectedContractChangeRequest?.actionContent.IPTUValue
                  )}
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.Area !==
                contract?.area && (
                <LabelInfoDetails
                  label={'Area (M²)'}
                  details={
                    props.selectedContractChangeRequest?.actionContent.Area
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.ClusterId !==
                contract?.clusterId && (
                <LabelInfoDetails
                  label={'ID Cluster'}
                  details={
                    props.selectedContractChangeRequest?.actionContent.ClusterId
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.RegionalId !==
                contract?.regionalId && (
                <LabelInfoDetails
                  label={'ID Regional'}
                  details={
                    props.selectedContractChangeRequest?.actionContent
                      .RegionalId
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .IPTURegistration !== contract?.iptuRegistration && (
                <LabelInfoDetails
                  label={'Matrícula IPTU'}
                  details={
                    props.selectedContractChangeRequest?.actionContent
                      .IPTURegistration
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.Data !==
                contract?.data && (
                <LabelInfoDetails
                  label={'Dados'}
                  details={
                    props.selectedContractChangeRequest?.actionContent.Data
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.Comments !==
                contract?.comments && (
                <LabelInfoDetails
                  label={'Observações'}
                  details={
                    props.selectedContractChangeRequest?.actionContent.Comments
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent.Origin !==
                contract?.origin && (
                <LabelInfoDetails
                  label={'Origem'}
                  details={
                    props.selectedContractChangeRequest?.actionContent.Origin
                  }
                />
              )}
              {props.selectedContractChangeRequest?.actionContent
                .ManualIdentifier !== contract?.manualIdentifier && (
                <LabelInfoDetails
                  label={'Identificador Manual do Contrato'}
                  details={
                    props.selectedContractChangeRequest?.actionContent
                      .ManualIdentifier
                  }
                />
              )}
              {getDescriptionByItemId(
                listTowerType,
                props.selectedContractChangeRequest?.actionContent.TowerTypeId
              ) !==
                getDescriptionByItemId(
                  listTowerType,
                  contract?.towerTypeId || ''
                ) && (
                <LabelInfoDetails
                  label={'Tipo de torre'}
                  details={getDescriptionByItemId(
                    listTowerType,
                    props.selectedContractChangeRequest?.actionContent
                      .TowerTypeId
                  )}
                />
              )}
              {getDescriptionByItemId(
                listTechnology,
                props.selectedContractChangeRequest?.actionContent.TechnologyId
              ) !==
                getDescriptionByItemId(
                  listTechnology,
                  contract?.technologyId || ''
                ) && (
                <LabelInfoDetails
                  label={'Tipo de tecnologia'}
                  details={getDescriptionByItemId(
                    listTechnology,
                    props.selectedContractChangeRequest?.actionContent
                      .TechnologyId
                  )}
                />
              )}
              {getDescriptionByItemId(
                listEngineeringType,
                props.selectedContractChangeRequest?.actionContent
                  .EngineeringTypeId
              ) !==
                getDescriptionByItemId(
                  listEngineeringType,
                  contract?.engineeringTypeId || ''
                ) && (
                <LabelInfoDetails
                  label={'Tipo de  engenharia'}
                  details={getDescriptionByItemId(
                    listEngineeringType,
                    props.selectedContractChangeRequest?.actionContent
                      .EngineeringTypeId
                  )}
                />
              )}
              {getDescriptionByItemId(
                listEquipmentType,
                props.selectedContractChangeRequest?.actionContent.EquipmentId
              ) !==
                getDescriptionByItemId(
                  listEquipmentType,
                  contract?.equipmentId || ''
                ) && (
                <LabelInfoDetails
                  label={'Tipo de  equipamento'}
                  details={getDescriptionByItemId(
                    listEquipmentType,
                    props.selectedContractChangeRequest?.actionContent
                      .EquipmentId
                  )}
                />
              )}
            </Flex>
          </Flex>
        </>
      );
    }
    return <></>;
  }
  return (
    <ModalStructureComponent
      isOpen={props.showModal}
      onClose={() => props.setShowModal()}
      title={`Valores do pedido de alteração`}
      maxW={
        props.selectedContractChangeRequest?.type === RequestType.Additive
          ? '90%'
          : '50%'
      }
      size={
        props.selectedContractChangeRequest?.type === RequestType.Additive
          ? '6xl'
          : '4xl'
      }
      footer={
        <Flex margin={4} justifyContent={'flex-end'}>
          <ButtonComponent
            margin={'0 4px 0 4px'}
            variant={'outline'}
            onSubmit={() => {
              props.setShowModal();
            }}
          >
            Fechar
          </ButtonComponent>
          <ButtonComponent
            disabled={
              props.selectedContractChangeRequest?.answeredBy?.name
                ? true
                : false
            }
            margin={'0 4px 0 4px'}
            colorScheme="red"
            onSubmit={() => {
              setApprove(false);
              setShowModalApprove(true);
            }}
          >
            Reprovar
          </ButtonComponent>
          <ButtonComponent
            disabled={
              props.selectedContractChangeRequest?.answeredBy?.name
                ? true
                : false
            }
            margin={'0 4px 0 4px'}
            onSubmit={() => {
              setApprove(true);
              setShowModalApprove(true);
            }}
          >
            Aprovar
          </ButtonComponent>
        </Flex>
      }
    >
      <Flex maxH={600} overflowY={'scroll'} flexDir={'column'}>
        <HeaderTextComponent fontSize={24} mb={2}>
          {`Tipo do pedido - ${props.selectedContractChangeRequest?.typeDescription}`}
        </HeaderTextComponent>
        <HeaderTextComponent mb={2} fontSize={22} subTitle>
          Informações do Contrato
        </HeaderTextComponent>
        <Flex justifyContent={'space-between'}>
          <LabelInfoDetails
            label="Número do contrato"
            details={props.selectedContractChangeRequest?.contractSerialNumber}
          />
          <LabelInfoDetails
            label="Locador"
            details={props.selectedContractChangeRequest?.requestedBy.name}
          />
          <Flex flexDirection={'column'} mr={8}>
            <TextComponent fontSize={16} fontWeight={'bold'}>
              Status
            </TextComponent>
            <StatusColorByTypeContract
              type={props.selectedContractChangeRequest?.contractStatus ?? 0}
            />
          </Flex>
        </Flex>
        <ReturnChangeRequestData
          type={props.selectedContractChangeRequest?.type}
        />
      </Flex>
      <AcceptMessageModalComponent
        approve={approve}
        showModal={showModalApprove}
        setShowModal={() => setShowModalApprove(false)}
        onAcceptModal={(message) => {
          putContractChangeRequest({
            id: props.selectedContractChangeRequest?.id || '',
            message: message,
            approve: approve,
          }).then((res) => {
            setShowModalApprove(false);
            props.refetch();
            showToast({
              toast,
              status: 'success',
              title: 'Sucesso',
              description: `Mudança ${
                approve ? 'Aprovada' : 'Reprovada'
              } com sucesso`,
            });
            props.setShowModal();
          });
        }}
      />
    </ModalStructureComponent>
  );
}
function getFrequencyNameByType(type: FrequencyContract | undefined) {
  if (type === FrequencyContract.Anual) {
    return 'Anual';
  }
  if (type === FrequencyContract.Bimestral) {
    return 'Bimestral';
  }
  if (type === FrequencyContract.Mensal) {
    return 'Mensal';
  }
  if (type === FrequencyContract.Semestral) {
    return 'Semestral';
  }
  if (type === FrequencyContract.Negociacao) {
    return 'Em Negociação';
  }

  return '';
}

function getIdexNameByIndex(index: ReadjustmentIndex | undefined) {
  if (index === ReadjustmentIndex.IPCA) {
    return 'IPCA';
  }
  if (index === ReadjustmentIndex.IPCAE) {
    return 'IPCAE';
  }
  if (index === ReadjustmentIndex.IPCDI) {
    return 'IPCDI';
  }
  if (index === ReadjustmentIndex.INPC) {
    return 'INPC';
  }
  if (index === ReadjustmentIndex.IGPM) {
    return 'IGPM';
  }
  if (index === ReadjustmentIndex.IGPDI) {
    return 'IGPDI';
  }
  if (index === ReadjustmentIndex.SELIC) {
    return 'SELIC';
  } else {
    return '';
  }
}

function getDescriptionByItemId(list: any, filteredFild: string) {
  const filteredList = list?.filter((e: any) => e.id === filteredFild);
  return filteredList?.map((e: any) => e.description).toString();
}
// function getPersonNameByItemId(list: any, filteredFild: string) {
//   const filteredList = list?.filter(
//     (e: any) => e.physicalOrJuridicalPersonId === filteredFild
//   );
//   return filteredList?.map((e: any) => e.name).toString();
// }
